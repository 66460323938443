<template>
  <div class="signup-main max">
    <!-- <div class="signup-image">
      <img src="../../assets/images/signup/signup-img.svg" alt="Signup Image" />
    </div> -->
    <form
      @submit="submitForm"
      class="signup-form"
      :class="{ loading: isLoading }"
    >
      <div v-show="alert.show" class="notifications-section">
        <div class="alert" :class="alert && alert.type ? alert.type : ''">
          <span class="alert-text">{{ alert.message }}</span
          ><i @click="closeAlert" class="fa-solid fa-x pointer"></i>
        </div>
      </div>
      <div class="input-wrapper">
        <input
          v-model="formData.username"
          placeholder="Username"
          class="input"
          type="text"
        />
      </div>
      <div class="input-wrapper">
        <input
          v-model="formData.email"
          placeholder="Email"
          class="input"
          type="email"
        />
      </div>
      <div class="input-wrapper">
        <vue-tel-input
          v-model="formData.phone"
          placeholder="Phone Number"
          :styleClasses="`tel-input-wrapper ${
            isNumberValid ? 'input-wrapper-valid' : 'input-wrapper-invalid'
          } `"
          type="number"
          @validate="validatePhone"
          @blur="handleBlur"
          :inputOptions="{
            placeholder: 'Phone Number',
            autofocus: false,
            styleClasses: [
              'input-phone',
              `${isNumberValid ? 'input-phone-valid' : 'input-phone-invalid'} `,
            ],
          }"
        />
        <input type="hidden" :value="$route.query.ref" />
      </div>
      <div class="input-wrapper">
        <input
          placeholder="Password"
          v-model="formData.password"
          class="input"
          type="password"
        />
      </div>

      <div class="footer">
        <span class="login-user-link"
          >Already have an account?
          <router-link :to="{ name: 'Login' }">Login</router-link>
        </span>
        <button class="btn" :disabled="isLoading">
          <i class="fa-solid fa-spinner spin" v-if="isLoading"></i>
          <span class="signup-text" v-else> Sign Up </span>
        </button>
      </div>
    </form>
  </div>
</template>
<script>
import { checkEmpty } from "../../utils/helpers";
import isEmail from "validator/lib/isEmail";

export default {
  name: "Signup",
  metaInfo: {
    title: "The Gainer",
    titleTemplate: "%s | Signup",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
  data() {
    return {
      numberWithCountryCode: "",
      isLoading: false,
      alert: {
        type: "danger",
        message: "",
        show: false,
      },
      isNumberValid: true,
      formData: {
        username: "",
        email: "",
        phone: "",
        password: "",
      },
    };
  },
  methods: {
    closeAlert() {
      this.alert = { ...this.alert, show: false };
    },
    submitForm(e) {
      e.preventDefault();

      if (
        checkEmpty(
          this.formData.username,
          this.formData.email,
          this.formData.phone,
          this.formData.password
        )
      ) {
        this.alert = {
          type: "danger",
          message: "Please fill all the fields",
          show: true,
        };
        return;
      } else if (!isEmail(this.formData.email)) {
        this.alert = {
          type: "danger",
          message: "Please enter a valid email",
          show: true,
        };
        return;
      } else if (!this.isNumberValid) {
        this.alert = {
          type: "danger",
          message: "Please enter a valid phone number",
          show: true,
        };
        return;
      }
      this.alert = {
        type: "",
        message: "",
        show: false,
      };
      this.isLoading = true;
      let data = {
        username: this.formData.username,
        email: this.formData.email,
        phone: this.numberWithCountryCode,
        password: this.formData.password,
      };
      if (this.$route.query.ref) {
        data.ref = this.$route.query.ref;
      }
      this.$http
        .post("/auth/signup", data)
        .then((res) => {
          this.isLoading = false;
          this.alert = {
            type: "success",
            message: res.data.message,
            show: true,
          };
          setTimeout(() => {
            this.$router.push({ name: "Login" });
          }, 2000);
        })
        .catch((err) => {
          if (!err || (!err.response && !window.navigator.onLine)) {
            this.isLoading = false;
            this.alert = {
              type: "danger",
              message: "You are currently offline",
              show: true,
            };
            return;
          } else if (!err || !err.response) {
            this.alert = {
              type: "danger",
              message: "Something went wrong",
              show: true,
            };
            return;
          }
          this.isLoading = false;
          if (err.response.data && err.response.data.message) {
            this.alert = {
              type: "danger",
              message: err.response.data.message,
              show: true,
            };
          } else {
            this.alert = {
              type: "danger",
              message: "Something went wrong",
              show: true,
            };
          }
        });
    },
    handleBlur() {
      // console.log(e);
    },
    validatePhone(e) {
      this.numberWithCountryCode = e.number;
      if (e.valid) {
        this.isNumberValid = true;
      } else {
        this.isNumberValid = false;
      }
    },
  },
};
</script>
<style lang="scss">
@import "./index.scss";
</style>
